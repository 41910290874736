import {Controller} from "@hotwired/stimulus"
import Cropper from 'cropperjs';

export default class extends Controller {

    static get targets() {
        return [
            "blob",
            "crop",
            "input",
            "modal",
            "overlay",
            "preview",
        ]
    }

    connect() {
        this.initSrc = this.cropTarget.src;

        this.closeModal = this.closeModal.bind(this)
        this.initCropper = this.initCropper.bind(this)
        this.onChange = this.onChange.bind(this)
        this.openModal = this.openModal.bind(this)
        this.resetCropper = this.resetCropper.bind(this)
    }

    closeModal() {
        this.overlayTarget.setAttribute('hidden', true);
        this.modalTarget.setAttribute('hidden', true);

        this.resetCropper();
    }

    initCropper(image) {
        this.cropTarget.src = image;
        this.cropper = new Cropper(this.cropTarget, {
            aspectRatio: 1,
        });
    }

    resetCropper() {
        this.cropTarget.src = this.initSrc;
        if (this.cropper) this.cropper.destroy();
    }

    openModal() {
        this.overlayTarget.removeAttribute('hidden');
        this.modalTarget.removeAttribute('hidden');
    }

    onChange() {
        const file = this.inputTargets
            .find(it => it.files && it.files[0])
            .files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = () => {
                this.initCropper(reader.result);
                this.openModal();
            }

            reader.readAsDataURL(file);
        }
    }

    save(event) {
        const canvas = this.cropper.getCroppedCanvas({
            height: 300,
            width: 300,
        });

        const blob = canvas.toDataURL();
        const parts = blob.split(',');

        this.blobTarget.value = parts[1];
        this.inputTarget.value = "";
        this.previewTarget.src = blob;

        this.closeModal();

        event.preventDefault();
    }
}